// Initial state of the SKU Reducer
export const initialSKUState = {
  loading: true,
  is_sku_loaded: false,
  error: "",
  sku: {},
};
//SKU Reducer
export const skuReducer = (state, action) => {
  switch (action.type) {
    case "GET_SKU_SUCCESS":
      return {
        loading: false,
        is_sku_loaded: true,
        error: "",
        sku: action.skuPayload,
      };

    case "GET_SKU_FAILED":
      return {
        loading: false,
        is_sku_loaded: false,
        error: "Something went wrong! The model cannot be displayed",
        sku: {},
      };

    default:
      return state;
  }
};
