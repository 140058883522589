// This is the dome ID which needs to exist in the webpage in order the widget to inject the content
// The width and the heigth needs to be set from the client, regaring their styling preferences
export const WebArPlayerInput = "webarplayercontainer";
export const WebArPlayerParent = "webarplayercontainer";
//Main path for all requests to the backend
//export const MainFowardURL = "http://192.168.178.27:3030";
//export const MainRequestURL = "http://192.168.178.27:8000/api/arplayer";
// DEVELOPMENT ENVIRONMENT //
//export const MainRequestURL = "https://api.huemenar.de/api/arplayer";
//export const MainFowardURL = "http://ar.huemenar.de.s3-website.eu-central-1.amazonaws.com";
// PRODUCTION ENVIRONMENT //
export const MainRequestURL = "https://api.huemenar.com/api/arplayer";
export const MainFowardURL = "http://ar.huemenar.com";
