import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { MainRequestURL } from "./functional/GlobalVariables";

import { skuReducer, initialSKUState } from "./reducers/SKUReducer";
import { modelReducer, initialModelState } from "./reducers/ModelReducer";
import { languageReducer, initialLanguageState } from "./reducers/LanguageReducer";

import ARPlayer from "./ARPlayer";

export const InitialSKUDataContext = React.createContext();
export const ModelContext = React.createContext();
export const UIMessagesContext = React.createContext();
export const AnonymousUserContext = React.createContext();

function MainApp(props) {
  // THis is the initial SKU coming from the Parameter in the URL
  const { customUrlSlug } = useParams();
  // Data from the first Request
  const [anonymousUser, setAnonymousUser] = useState("");
  const [skuID, setSkuID] = useState();
  const [backURL, setBackURL] = useState();
  const [languageCode, setLanguageCode] = useState();
  const [InitialDataloaded, setInitialDataLoaded] = useState(false);

  const [skuData, skuDispatch] = useReducer(skuReducer, initialSKUState);
  const [modelData, modelDispatch] = useReducer(modelReducer, initialModelState);
  const [uiMessageData, uiMessageDispatch] = useReducer(languageReducer, initialLanguageState);

  const [oldRecords, setOldRecords] = useState();
  /*
  useEffect(() => {
    axios
      .get(`${MainRequestURL}/customurls/`)
      .then((response) => {
        //console.log("Old Records");
        setOldRecords(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    if (oldRecords) {
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(today.getDate() - 2);
      var date =
        yesterday.getFullYear() +
        "-" +
        ("0" + (yesterday.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + yesterday.getDate()).slice(-2);

      for (let i = 0; i < oldRecords.length; i++) {
        if (oldRecords[i]) {
          if (date > oldRecords[i].created_at.substring(0, 10)) {
            //console.log(date);
            //console.log("I need to delete these records");
            axios
              .delete(`${MainRequestURL}/customurls/${oldRecords[i].slug}/`)
              .then((response) => {
                //console.log("The Record was deleted");
              })
              .catch((error) => {
                //console.log(error.message);
              });
          }
        }
      }
    }
  }, [oldRecords]);
  */
  useEffect(() => {
    //Get User, SKU, Back_URL from custom URL
    axios
      .get(`${MainRequestURL}/customurls/${customUrlSlug}/`)
      .then((response) => {
        setAnonymousUser(response.data.user);
        setSkuID(response.data.sku);
        setBackURL(response.data.back_url);
        setLanguageCode(response.data.language);
        setInitialDataLoaded(true);
        //console.log("Initial Data Custom URL");
        //console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  useEffect(() => {
    // Get SKU ID to be desplayed
    if (InitialDataloaded) {
      // Get SKU
      axios
        .get(`${MainRequestURL}/skus/${skuID}/`)
        .then((response) => {
          skuDispatch({ type: "GET_SKU_SUCCESS", skuPayload: response.data });
          //console.log("SKU");
          //console.log(response.data);
        })
        .catch((error) => {
          skuDispatch({ type: "GET_SKU_FAILED" });
          console.log(error.message);
        });
      // Get Languages
      axios
        .get(`${MainRequestURL}/languages/`)
        .then((response) => {
          uiMessageDispatch({ type: "GET_UIMESSAGES_SUCCESS", uiMessagesPayload: response.data });
          //console.log("LANGUAGES");
          //console.log(response.data);
        })
        .catch((error) => {
          uiMessageDispatch({ type: "GET_UIMESSAGES_FAILED" });
          console.log(error.message);
        });
    }
  }, [InitialDataloaded]);

  // Get te corresponding Model to the Initial SKU
  useEffect(() => {
    if (skuData.is_sku_loaded) {
      axios
        .get(`${MainRequestURL}/models/${skuData.sku.model.id}/`)
        .then((response) => {
          modelDispatch({ type: "GET_MODEL_SUCCESS", modelPayload: response.data });
          //console.log("MODEL");
          //console.log(response.data);
        })
        .catch((error) => {
          skuDispatch({ type: "GET_MODEL_FAILED" });
          console.log(error.message);
        });
    }
  }, [skuData.is_sku_loaded]);

  return (
    <>
      <InitialSKUDataContext.Provider value={skuData}>
        <ModelContext.Provider value={modelData}>
          <UIMessagesContext.Provider value={uiMessageData}>
            <AnonymousUserContext.Provider value={anonymousUser}>
              {InitialDataloaded &&
              modelData.is_model_loaded &&
              uiMessageData.is_language_loaded ? (
                <ARPlayer languageCode={languageCode} />
              ) : (
                ""
              )}
            </AnonymousUserContext.Provider>
          </UIMessagesContext.Provider>
        </ModelContext.Provider>
      </InitialSKUDataContext.Provider>
    </>
  );
}

export default MainApp;
