// Initial state of the SKU Reducer
export const initialLanguageState = {
  loading: true,
  is_language_loaded: false,
  error: "",
  uiMessages: {},
};
//SKU Reducer
export const languageReducer = (state, action) => {
  switch (action.type) {
    case "GET_UIMESSAGES_SUCCESS":
      return {
        loading: false,
        is_language_loaded: true,
        error: "",
        uiMessages: action.uiMessagesPayload,
      };

    case "GET_UIMESSAGES_FAILED":
      return {
        loading: false,
        is_language_loaded: false,
        error: "Something went wrong! The model cannot be displayed",
        uiMessages: {},
      };

    default:
      return state;
  }
};
