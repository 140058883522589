import React, { useEffect, useState, useContext } from "react";
import HuemenARPlayer from "./functional/HuemenARPlayer";

import ARErrorPopup from "./ARErrorPopup";

import { InitialSKUDataContext, ModelContext, UIMessagesContext } from "./MainApp";

function ARPlayer(props) {
  // The Initial SKU to be loaded Context coming from MainApp.js
  const initialSKU = useContext(InitialSKUDataContext);
  // Model Data related to the Initial SKU. Context coming from MainApp.js
  const modelData = useContext(ModelContext);
  // Language Data
  const uiMessageData = useContext(UIMessagesContext);
  const [uiMessages] = useState(
    uiMessageData.uiMessages.find((o) => o.language_code === props.languageCode)
  );

  const [errorpopupvisibility, setErrorpopupvisibility] = useState(false);

  // Detects if AR failed
  useEffect(() => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      "T" +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    //console.log(date);
    const modelViewer = document.getElementById("webarplayer");
    modelViewer.addEventListener("load", () => {
      modelViewer.activateAR();
    });
    modelViewer.addEventListener("ar-status", (event) => {
      if (event.detail.status === "failed") {
        setErrorpopupvisibility(true);
      }
    });
  }, []);

  useEffect(() => {
    const modelViewer = document.getElementById("webarplayer");

    modelViewer.shadowRoot.querySelectorAll(".progress-bar")[0].style.visibility = "hidden";
  }, []);

  return (
    <>
      <huemen-ar-player
        style={{ width: "100%", height: "100%", background: "white" }}
        // Events

        // SKU Data
        src={initialSKU.sku.glb_file_3D}
        ios-src={initialSKU.sku.usdz_file}
        alt={initialSKU.sku.name}
        poster={initialSKU.sku.poster}
        // Model Data
        camera-orbit={modelData.model.model_camera_orbit.settings}
        max-camera-orbit={modelData.model.model_max_camera_orbit.settings}
        min-camera-orbit={modelData.model.model_min_camera_orbit.settings}
        exposure={modelData.model.model_exposure.exposure}
        shadow-intensity={modelData.model.model_shadow.shadow_intensity}
        shadow-softness={modelData.model.model_shadow.shadow_softness}
        //General Options

        id="webarplayer"
        reveal="auto"
        ar
        ar-modes="webxr scene-viewer quick-look"
        ar-scale="fixed"
        data-js-focus-visible
        camera-controls
        interaction-prompt="none"
      ></huemen-ar-player>
      <p style={{ textAlign: "center", marginTop: "30px" }}>
        Previewing the model in Augmented Reality...
      </p>
      {uiMessages && errorpopupvisibility ? (
        <ARErrorPopup
          title={uiMessages.ar_error_massage_title}
          text={uiMessages.ar_error_massage_content}
          color={initialSKU.sku.model.brand.primary_color}
          visibility={setErrorpopupvisibility}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ARPlayer;
