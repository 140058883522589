import React from "react";

function ARIcon(props) {
  return (
    <svg width="82" height="86" viewBox="0 0 82 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <path
          d="M41 2L5.46667 22.5"
          stroke="#828282"
          strokeWidth="1.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.4668 62.1333L5.4668 22.4999"
          stroke="#828282"
          strokeWidth="1.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.5334 62.1333L76.5335 22.4999"
          stroke="#828282"
          strokeWidth="1.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.104 22.5L41.0001 2"
          stroke="#828282"
          strokeWidth="1.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.104 63.5L41.0001 84"
          stroke="#828282"
          strokeWidth="1.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.4668 63.5L41.5707 84"
          stroke="#828282"
          strokeWidth="1.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M4.73389 52.5667L4.78295 63.585L14.3006 69.1366"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78322 63.585L16.6189 56.7516"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8015 52.5667L76.7525 63.5851L67.2348 69.1367"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.7522 63.5851L64.9165 56.7517"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.5667 7.46664L41 1.99998L31.4333 7.46664"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 1.99999V15.6667"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8015 33.4333L76.7525 22.4149L67.2348 16.8633"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.7522 22.4149L64.9165 29.2483"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.73389 33.4333L4.78295 22.415L14.3006 16.8634"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78322 22.415L16.6189 29.2484"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4333 78.5334L41 84L50.5667 78.5334"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 84V70.3333"
        stroke="#828282"
        strokeWidth="2.73333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4948 47.5081H26.8903L24.7178 53.7006H21.8667L29.8497 32.0667H32.5183L40.5013 53.7006H37.6502L35.4948 47.5081ZM27.6829 45.2272H34.7022L31.3151 35.5564H31.0757L27.6829 45.2272Z"
        fill="#828282"
      />
      <path
        d="M46.9952 53.7006H44.3152V32.0667H52.5776C56.7459 32.0667 59.4715 34.6155 59.4715 38.5158C59.4715 41.5265 57.835 43.8701 55.1265 44.7197L60.1329 53.7006H56.9682L52.3381 45.1474H46.9952V53.7006ZM46.9952 42.7411H52.2982C55.0694 42.7411 56.6489 41.2585 56.6489 38.6013C56.6489 35.9441 54.9725 34.4616 52.1784 34.4616H46.9952V42.7411Z"
        fill="#828282"
      />
    </svg>
  );
}

export default ARIcon;
