// Initial state of the Model Reducer
export const initialModelState = {
  loading: true,
  is_model_loaded: false,
  error: "",
  model: {},
};
//Model Reducer
export const modelReducer = (state, action) => {
  switch (action.type) {
    case "GET_MODEL_SUCCESS":
      return {
        loading: false,
        is_model_loaded: true,
        error: "",
        model: action.modelPayload,
      };

    case "GET_MODEL_FAILED":
      return {
        loading: false,
        is_model_loaded: false,
        error: "Something went wrong! The model cannot be displayed",
        model: {},
      };

    default:
      return state;
  }
};
