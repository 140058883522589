import React, { useState, useRef, useEffect } from "react";
import ARErrorIcon from "./svgComponents/ARErrorIcon";

import useClientSize from "./functional/customHooks/useClientSize";

function ARErrorPopup(props) {
  // Create a ref to the AR Button button
  const closeButton = useRef();
  const [color, setColor] = useState("white");
  const [background, setBackground] = useState(props.color);
  const [width, setWidth] = useState("300");

  const popupStyles = {
    popupContainer: {
      background: "white",
      width: "300px",
      height: "400px",
      position: "absolute",
      left: "0px",
      right: "0px",
      top: "0px",
      bottom: "0px",
      margin: "auto",
      borderRadius: "12px",
      border: "none",
    },
    iconContainer: {
      paddingTop: "26px",
      background: "white",
      textAlign: "center",
      width: "85px",
      height: "122px",
      margin: "auto",
      borderRadius: "12px",
      border: "none",
    },
    title: {
      paddingTop: "8px",
      maxWidth: "85%",
      background: "white",
      marginLeft: "20px",
      marginRight: "20px",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "16px",
    },
    text: {
      maxWidth: "85%",
      maxHeight: "140px",
      background: "white",
      marginLeft: "20px",
      marginRight: "20px",
      textAlign: "center",
      fontSize: "14px",
      lineHeight: "16.41px",
      //overflowX: "hidden",
      //overflowY: "auto",
    },
    closeButton: {
      position: "absolute",
      bottom: "20px",
      left: "50%",
      webKitTransform: "translateX(-50%)",
      transform: "translateX(-50%)",
      padding: "2px",
      background: props.color,
      width: "260px",
      height: "48px",
      borderRadius: "24px",
      border: "none",
      boxShadow: "0px 4px 0px rgba(145, 145, 145, 0.25)",
      transition: "all 0.3s ease",
    },
    closeButtonContainer: {
      width: "256px",
      height: "44px",
      background: background,
      borderRadius: "22px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "20px",
      fontWeight: "bold",
      color: color,
      transition: "all 0.3s ease",
    },
  };

  const onCloseButtonHover = () => {
    setBackground("white");
    setColor(props.color);
  };
  const onCloseButtonHoverOut = () => {
    setBackground(props.color);
    setColor("white");
  };
  const onCloseButtonClicked = () => {
    props.visibility(false);
  };
  return (
    <div style={popupStyles.popupContainer}>
      <div style={popupStyles.iconContainer}>
        <ARErrorIcon />
      </div>
      <div style={popupStyles.title}>{props.title}</div>
      <div style={popupStyles.text}>{props.text}</div>
      <button
        onPointerOver={onCloseButtonHover}
        onPointerLeave={onCloseButtonHoverOut}
        onClick={onCloseButtonClicked}
        ref={closeButton}
        className="closeButton"
        style={popupStyles.closeButton}
      >
        <div className="arIconContainer" style={popupStyles.closeButtonContainer}>
          <div className="arIcon">OK</div>
        </div>
      </button>
    </div>
  );
}

export default ARErrorPopup;
